<template>
  <div class="container kuan">
    <div class="main">
      <!-- <div class="zezhao" v-if="zezhao||weihu">
        <div class="zezhao_tip" v-if="zezhao">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
        <div class="zezhao_tip" v-if="weihu">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}正在维护</h2>

          </div>
        </div>

      </div> -->
      <top-timer></top-timer>
      <div class="container kuan flex64">
        <div class="main">
          <div class="player listmain type-f3d f3d game-f3d game-f3d-2zh">

           
          </div>
          <!-- 注单号码 -->
          <div class="player listmain  tuoduopeng" :class="dop==1?'h2zd_l':'h2zd_h'">
            <div class="data">
              <h3>注单号码 <span>共 <em class="red">{{resultData.length}} </em>种组合 </span></h3>

              <!-- <div class="dantuo-li">

          </div> -->
              <div class="dantuo-sc " :class="dop==1?'h2zd_ld':'h2zd_hd'">
                <div>
                  <span v-for="(item,index) in resultData" :key="index">{{item.label}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="other">
          <!-- <div class="zezhao" v-if="zezhao||weihu"></div> -->
          <div class="right-com">

            <div style="height:1px"></div>
             <div class="kua_wrap">

              <label class="kualabel" v-for="item in kuadData" :key="item.label">
            {{ item.label }}<input type="checkbox" @change="changeItem($event,item)" v-model="item.selected" :checked="item.selected" >
                    </label>
            
             </div>
                      
        
           
          
            <section  >
              
              <div  class="btnaa">
                <span  class="button" @click="filterKuaData">生成</span>
                <span  class="button" @click="resetKuaCondition">重置</span>
              </div>
              </section>

          </div>
        </div>
      </div>
       

      <yushe />
    </div>
    
  </div>
</template>

<script>
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import kxmixins from "../mixins/kxmixinspl3";

import mixins from "../mixins/mainMixins";
export default {
  name: "kua",
  props: [""],
  mixins: [mixins,kxmixins],
  components: {
    yushe,
    topTimer,
  },
  data() {
    return {
      groupnames:'zh',
      // 长龙 tab
      changelongActive: "1",
      // 连续开  连续未开 tab
      kaiActive: "1",
      rowsData: [
        
      ],
      kuadData:[],
     
      labelArr: ['和值0','和值1','和值2','和值3','和值4','和值5','和值6','和值7','和值8','和值9',
      '和值10','和值11','和值12','和值13','和值14','和值15','和值16','和值17','和值18','和值19',
      '和值20','和值21','和值22','和值23','和值24','和值25','和值26','和值27',
    ],
     
    
    };
  },
  watch: {
    resetCount(){
      this.kuadData.forEach(item=>{
        item.selected=false
      })
      this.$nextTick(()=>{
        this.resultData=[]
      })
    },
    rowsData: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          let arr = [];
          val.forEach((item) => {
           
              if (item.money) {
                let obj = {
                  label: item.label,
                  Odds: item.Odds,
                  title: '',
                  id: item.ResultID,
                  money: Number(item.money),
                };
                arr.push(obj);
              
              }
            
          });

          // console.log(arr, "arrrr");
          if (arr.length > 0) {
            this.$store.commit("setXZList", arr);
          } else {
            this.$store.commit("setXZList", []);
          }
        });
      },
    },
    // j监听数据
    oddData1: {
      immediate: true,
      deep: true,
      handler(resAArr) {
      
         let idarr=[3674,5344,5345,5346,5347,5348,5349,3675,3676,3677,3678,3679,3680,3681,3682,3683,3684,3685,3686,3687,3688,3689,5350,5351,5352,5353,5354,5355]
        let arr1 = this.ddFilterData1(resAArr, idarr, this.labelArr);
       
       this.kuadData=arr1
        this.$forceUpdate();
      },
    },
  },

  created() {},

  methods: {
    changeItem($event,item){
      console.log(item)
    },
    setLongTab(n) {
      this.changelongActive = n;
    },
    setKaiTab(n) {
      this.kaiActive = n;
    },
  },
};
</script>
<style scoped>
.kua_wrap{
  width: 559px;
  display: flex;
  flex-wrap: wrap;
}
.kualabel input{
  margin-left: 2px;
}
.kualabel{

  margin: 5px 0;
  display:flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 30px;
  font-size: 14px;
  font-weight: bold;
  border-right: 1px solid #e0e0e0;
}
.type-f3d .greenball {
  /* background-image: url("../../assets/3d.png"); */
}
</style>